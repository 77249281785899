import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import Tickets from '../Tickets/Tickets';
import styles from './Quote.module.scss';

function Quote(props) {
  const { tickets, tabID } = props;
  const { t } = useTranslation('common');
  const ticketsList = tickets && tickets.map((ticket) => (
    <Tickets
      key={ticket.order_ticket_id}
      id={ticket.order_ticket_id}
      title={ticket.title}
      img={ticket.img}
      date={ticket.date}
      checkout_date={ticket.checkout_date}
      time={ticket.time}
      tickets={ticket.ticket_numbers}
      price={ticket.price}
      currency={ticket.currency}
      attributes={ticket.attributes}
      quantity={ticket.quantity}
      subtitle={ticket.subtitle}
    />
  ));

  return (
    <div id={tabID} className={styles.quote}>
      <h2 className={styles.title}>{t('payment.quote_tab')}</h2>
      <ul className={styles.ticketsList}>{ticketsList}</ul>
    </div>
  );
}

export default Quote;
