import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import commonTranslationsDE from './de/common.json';
import commonTranslationsEN from './en/common.json';
import { getAppConfig } from '../config/config';

const resources = {
  en: {
    common: commonTranslationsEN,
  },
  de: {
    common: commonTranslationsDE,
  },
};

const { language_prefix: lang } = getAppConfig();

i18n
  .use(initReactI18next)
  .init({
    interpolation: { escapeValue: false },
    keySeparator: '.',
    resources,
    lng: lang || 'en',
    fallbackLng: 'en',
  });

export default i18n;
