import {
  useRef, useState, useEffect, useContext,
} from 'react';
import * as EmailValidator from 'email-validator';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import parse from 'html-react-parser';
import SessionContext from '../../../context/SessionContext';
import styles from './LoginForm.module.scss';
import API from '../../../api/phx';
import '../../../translations/i18n';
import { getAppConfig } from '../../../config/config';
import * as utils from '../../../utils/OrderUtils';

function LoginForm({ isCCNS }) {
  const emailRef = useRef();
  const errRef = useRef();
  const orderIdRef = useRef();

  const [errMsg, setErrMsg] = useState('');

  const [email, setEmail] = useState('');
  const [emailIsValid, setEmailIsValid] = useState();
  const [orderId, setOrderId] = useState('');
  const [orderIdIsValid, setOrderIdIsValid] = useState();
  const [formIsValid, setFormIsValid] = useState();

  const [contactNumber, setContactNumber] = useState('');

  const [cookies, setCookie] = useCookies(['customer']);

  const config = getAppConfig();
  const { t } = useTranslation('common');

  const navigate = useNavigate();

  const location = useLocation();

  // Check form is valid
  useEffect(() => {
    setFormIsValid(
      EmailValidator.validate(email) && orderId.trim().length >= 5,
    );
  }, [email, orderId]);

  const validateEmailHandler = () => {
    setEmailIsValid(EmailValidator.validate(email));
  };

  const validateOrderIdHandler = () => {
    setOrderIdIsValid(orderId.trim().length >= 5);
  };

  const sessionCtx = useContext(SessionContext);

  useEffect(() => {
    orderIdRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg('');
  }, [email, orderId]);

  const getContactNumber = async () => {
    try {
      const res = await API.get(`/content?geo=${utils.getLang()}`);
      setContactNumber(res.data.phone);
    } catch (err) {
      console.error('Error:', err);
    }
  };

  useEffect(() => {
    if (location.state && location.state.orderStatus === 'customer_contacted_no_sale' && !email && !orderId) {
      getContactNumber();
      if (utils.getLang() === 'en-us' && config.brand === 'ATD') {
        setErrMsg(`Oops! The quote we provided you has recently expired. Not to worry, we can easily prepare an updated one for you. Just <a href="/${config.language_prefix}/contact-us">contact us</a>.`);
      } else if (config.brand === 'AA') {
        setErrMsg('Oops! The quote we provided you has recently expired. Not to worry, we can easily prepare an updated one for you. Just <a href="/contactus.php">contact us</a>.');
      } else {
        setErrMsg('Oops! The quote we provided you has now expired. Not to worry, we can easily prepare an updated one for you. Just contact us on ');
      }
    }
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!emailIsValid) {
      emailRef.current.focus();
    }
    if (!orderIdIsValid) {
      orderIdRef.current.focus();
    }

    if (formIsValid) {
      // Log the user in.
      const loginPost = `username=${encodeURIComponent(email)}&order_id=${orderId}`;
      const loginConfig = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };
      try {
        const requestUrl = utils.appendGeoParam('/session', config.brand);
        const response = await API.post(requestUrl, loginPost, loginConfig);
        const sid = response.data.id;
        const userId = response.data.user_id;
        setEmail('');
        setOrderId('');
        const { onLogin } = sessionCtx;
        onLogin(userId, orderId, sid);

        setCookie('user', userId, { path: '/' });
        setCookie('order', orderId, { path: '/' });

        const orderUrl = utils.prependLangUrlPath(`/customer/order/${orderId}`, config.brand, config.language_prefix);

        // Add logged in class to parent body.
        const parentBody = document.body;
        if (parentBody) {
          parentBody.classList.add('logged-in');
        }

        // Fix for React state issues with header login for Next Apps & AA
        if (!utils.isATBrand()) {
          window.location.replace(orderUrl);
        } else {
          navigate(orderUrl);
        }
      } catch (err) {
        if (!err.response) {
          setErrMsg('No Server Response');
        } else if (err.response.status === 400) {
          setErrMsg('Missing email address or OrderId');
        } else if (err.response.status === 401) {
          setErrMsg('Unauthorized');
        } else {
          setErrMsg('Please check your login details and try again.');
        }
      }
    }
  };

  return (
    <section className={styles.login}>
      <h2 className={styles.customerAccountsSubtitle}>{t('login.subtitle')}</h2>
      <p className={styles.customerAccountsIntro}>{t('login.text')}</p>
      {errMsg && (
      <p ref={errRef} className={styles.errmsg} aria-live="assertive">
        {parse(t(errMsg))}
        {isCCNS && utils.getLang() !== 'en-us' && config.brand !== 'AA' && `${contactNumber}.`}
      </p>
      ) }
      <form onSubmit={handleSubmit}>
        <fieldset className={styles.fieldset}>
          <div className={styles.field}>
            <label className={styles.label} htmlFor="orderId">
              {t('login.order_id')}
              {' '}
              <span title="Required">*</span>
            </label>
            <span className={`${styles.inputWrap} validd errorr`}>
              <span className={styles.prefix}>
                {config.brand}
                -
              </span>
              <input
                className={styles.inputNumber}
                type="number"
                id="orderId"
                ref={orderIdRef}
                onChange={(e) => setOrderId(e.target.value)}
                onBlur={validateOrderIdHandler}
                value={orderId}
                placeholder={t('login.input1')}
                required
                onWheel={(e) => e.target.blur()}
              />
            </span>
            <p className={styles.error} />
          </div>
          <div className={styles.field}>
            <label className={styles.label} htmlFor="email">
              {t('login.email')}
              {' '}
              <span title="Required">*</span>
            </label>
            <span className={`${styles.inputWrap} validd errorr`}>
              <input
                className={`${styles.inputEmail} gb-mask`}
                type="email"
                id="email"
                ref={emailRef}
                onChange={(e) => setEmail(e.target.value)}
                onBlur={validateEmailHandler}
                value={email}
                placeholder={t('login.input2')}
                required
              />
            </span>
            <p className={styles.error} />
          </div>
          <button className={`${styles.button} loadingg`} disabled={!formIsValid} type="submit">{t('login.button_text')}</button>
        </fieldset>
      </form>
    </section>
  );
}

export default LoginForm;
