import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { applePay } from './ApplePay';
import styles from './ApplePay.module.scss';
import { getLang } from '../../../../utils/OrderUtils';
import PaymentContext from '../../../../context/PaymentContext';

function ApplePayTab({ order, payAmount }) {
  const { t } = useTranslation('common');

  const paymentCtx = useContext(PaymentContext);
  const { paymentData } = paymentCtx;

  return (
    <div className={styles.applepay}>
      <p>{t('Apple_pay_info')}</p>
      {/* eslint-disable-next-line */}
      <button className={styles.applePayButton} onClick={() => applePay(order, payAmount, paymentData)}></button>
    </div>
  );
}

export default ApplePayTab;
