import { useTranslation } from 'react-i18next';
import Tickets from '../Tickets/Tickets';
import styles from './TicketDownload.module.scss';
import '../../../translations/i18n';

function TicketDownload(props) {
  const { t } = useTranslation('common');
  const { tabID, vouchers } = props;
  // Sort products ordered by price, then quantity
  vouchers.sort((a, b) => (b.quantity < a.quantity ? -1 : 1)).sort((a, b) => (b.price < a.price ? -1 : 1));

  const orderTickets = vouchers && vouchers.map((ticket) => (
    <Tickets
      key={ticket.vouchers.voucher_id}
      id={ticket.vouchers.voucher_id}
      title={ticket.title}
      img={ticket.img}
      date={ticket.date}
      time={ticket.time}
      tickets={ticket.tickets}
      price={ticket.price}
      currency={ticket.currency}
      vouchers={ticket.vouchers}
      data={ticket.data}
      orderStatus={ticket.order_status}
    />
  ));

  return (
    <div id={tabID} className={styles.downloadTickets}>
      <h2 className={styles.title}>{t('payment.tickets_download_tab')}</h2>
      <ul className={styles.ticketsList}>{orderTickets}</ul>
    </div>
  );
}

export default TicketDownload;
