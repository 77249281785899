import { useState, useEffect, useCallback } from 'react';
import API from '../api/phx';

const useCountries = () => {
  const [countries, setCountries] = useState([]);
  const fetchData = useCallback(async () => {
    const resp = await API.get('/countries');
    const data = await resp?.data;
    setCountries(data.data);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { countries };
};

export default useCountries;
