import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import { getAppConfig } from '../../../config/config';
import styles from './HeaderInfo.module.scss';
import { formatName } from '../../../utils/OrderUtils';

const config = getAppConfig();

function HeaderInfo(props) {
  const { orderId, status, address } = props;
  let orderStatus = status && status.replaceAll('_', ' ');
  orderStatus = orderStatus && orderStatus.charAt(0).toUpperCase() + orderStatus.slice(1);
  const { t } = useTranslation('common');

  return (
    <>
      <h1 className={styles.accountsTitle}>
        {t('account.header.order')}
        {' '}
        {orderId}
      </h1>
      <h2 className={styles.accountsName}>
        {formatName(address.fname, address.lname)}
      </h2>
      <h2 className={styles.accountsStatus}>
        {t('account.header.status')}
        :
        {' '}
        {t(orderStatus)}
      </h2>
      {
        config.faq_url !== ''
          && (
          <p className={styles.accountsIntro}>
            {t('account.header.faq_text')}
            {' '}
            <a href={config.faq_url}>{t('account.header.faq_link_text')}</a>
          </p>
          )
      }
    </>
  );
}

export default HeaderInfo;
