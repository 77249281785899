import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import '../../translations/i18n';
import { useState, useEffect } from 'react';
import API from '../../api/phx';
import * as utils from '../../utils/OrderUtils';
import { getAppConfig } from '../../config/config';
import styles from '../Account/Account.module.scss';

function Logout() {
  const { t } = useTranslation('common');
  const [cookies, removeCookie] = useCookies();
  const config = getAppConfig();
  const [isLoggingOut, setIsLoggingOut] = useState(true);

  useEffect(() => {
    if (cookies.order) {
      removeCookie('order', { path: '/' });
    }
    if (cookies.user) {
      removeCookie('user', { path: '/' });
    }
    const sessionId = sessionStorage.getItem('sid');
    localStorage.removeItem('sid');
    sessionStorage.removeItem('sid');
    sessionStorage.removeItem('orderId');

    const deleteUrl = utils.appendGeoParam('/session', config.brand);
    if (config.brand === 'UO' || config.brand === 'SW') {
      API.delete(deleteUrl, { headers: { Authorization: `Basic ${sessionId}` } });
    } else {
      API.delete(deleteUrl);
    }

    // Fix for A/B test react state conflicts with nextjs state on logout
    if (sessionId && config.brand === 'UO') {
      window.location.reload(false);
    }

    // Remove logged in class to parent body.
    const parentBody = document.body;
    if (parentBody) {
      parentBody.classList.remove('logged-in');
    }

    // If on AA force refresh to change state of navbar
    if (sessionId && config.brand === 'AA') {
      window.location.reload(false);
    } else {
      setIsLoggingOut(false);
    }
  }, [config]);

  return (
    <div>
      {isLoggingOut ? (
        <p className={styles.loading}>Logging out ... </p>
      ) : (
        <>
          <h1>{t('logout.title')}</h1>
          <p>{t('logout.text')}</p>
        </>
      )}
    </div>
  );
}

export default Logout;
