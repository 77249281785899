import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { getAppConfig, getSiteConfig } from '../../../config/config';
import styles from './InfoBlock.module.scss';
import * as utils from '../../../utils/OrderUtils';

function InfoBlock() {
  const { t } = useTranslation('common');
  const config = getAppConfig();

  const [showPaymentInfo, setShowPaymentInfo] = useState(false);

  useEffect(() => {
    const lang = utils.getLang();
    const siteConfig = getSiteConfig();
    const langs = siteConfig.depositEnabledLangs;
    if (langs.includes(lang)) {
      setShowPaymentInfo(true);
    }
  }, []);

  return (
    <>
      <h2 className={styles.customerAccountsSubtitle}>{t('login.info_box.title')}</h2>
      <p className={styles.customerAccountsIntro}>{t('login.info_box.info_p')}</p>
      <ul className={styles.list}>
        { showPaymentInfo && <li>{t('login.info_box.info_list1')}</li>}
        <li>{t('login.info_box.info_list2')}</li>
        <li>{t('login.info_box.info_list3')}</li>
      </ul>
      <p className={styles.linkBtn}><a href="/">{t('login.info_box.link_text')}</a></p>
      <h2 className={styles.customerAccountsSubtitle}>{t('login.info_box.get_in_touch')}</h2>
      <p className={styles.customerAccountsIntro}>{t('login.info_box.contact_text')}</p>
      <p className={styles.telephone}>
        <a href={`tel:${config.phone_number}`}>{config.phone_number}</a>
      </p>
      <p className={styles.opening}>{t('login.info_box.opening_hours')}</p>
    </>
  );
}

export default InfoBlock;
