import { useEffect, useState } from 'react';
import { getLang, getEnv } from '../../../utils/OrderUtils';

function PageView({
  userEmail,
  orderId,
  createdBy,
}) {
  const userId = document.cookie.split('; ').find((row) => row.startsWith('user='))?.split('=')[1];

  // Handle page data.
  useEffect(() => {
    if (!window.dataLayer) {
      window.dataLayer = [];
    }
    window.dataLayer[0] = window.dataLayer[0] || {};
    const page = {
      node_type: 'customer_accounts',
      node_title: 'Customer Accounts',
      user_type: userId,
      user_email: userEmail,
      order_number: orderId,
      environment: getEnv(),
      domain: getLang(),
    };

    window.dataLayer[0].page = page;
  }, [userId, userEmail, orderId]);

  // Handle login event.
  useEffect(() => {
    const event = {
      event: 'login',
      user: {
        email: userEmail,
        id: localStorage.sid,
        login_order_id: orderId,
        user_id: userId,
        username: createdBy,
      },
    };

    // Check if login event already exists.
    const filtered = window.dataLayer.filter((loginEvent) => {
      if (loginEvent.event === 'login') {
        return loginEvent;
      }
      return null;
    });

    // If login event doesn't exist push in dataLayer array.
    if (filtered.length === 0 && userEmail && orderId && createdBy) {
      window.dataLayer.push(event);
    }
  }, [window.dataLayer, userEmail, orderId, createdBy]);
}

export default PageView;
