import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import Tickets from '../Tickets/Tickets';
import styles from './TicketsToPost.module.scss';

function TicketsToPost(props) {
  const { t } = useTranslation('common');
  const { tickets, tabID } = props;

  const ticketList = tickets && tickets.map((ticket) => (
    <Tickets
      key={ticket.order_ticket_id}
      id={ticket.order_ticket_id}
      title={ticket.title}
      img={ticket.img}
      date={ticket.date}
      checkout_date={ticket.checkout_date}
      time={ticket.time}
      tickets={ticket.ticket_numbers}
      price={ticket.price}
      currency={ticket.currency}
    />
  ));

  return (
    <div id={tabID} className={styles.ticketsToPost}>
      <h2 className={styles.title}>{t('payment.tickets_post_tab')}</h2>
      <ul className={styles.ticketsList}>{ticketList}</ul>
    </div>
  );
}

export default TicketsToPost;
