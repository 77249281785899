import './App.css';
import { useContext } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './components/Login/Login';
import SessionContext from './context/SessionContext';
import Account from './components/Account/Account';
import Logout from './components/Logout/Logout';
import { isATBrand } from './utils/OrderUtils';

function App() {
  const pathBase = isATBrand() ? ':lang/customer/' : 'customer/';
  const sessionCtx = useContext(SessionContext);
  const { isLoggedIn } = sessionCtx;

  return (
    <BrowserRouter>
      <Routes>
        <Route path={`${pathBase}order/logout`} element={<Logout />} />
        <Route path={`${pathBase}logout`} element={<Logout />} />
        <Route path={`${pathBase}order/login`} element={<Login isLoggedIn={isLoggedIn} />} />
        <Route path={`${pathBase}login`} element={<Login isLoggedIn={isLoggedIn} />} />
        <Route path={`${pathBase}order/:orderId`} element={<Account isLoggedIn={isLoggedIn} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
