import AppConfigReducerInitialState from '../reducers/AppConfigReducer/AppConfigReducerInitialState';

/** Get the app config. */
export const getAppConfig = () => {
  // Get the site config from the Drupal settings.
  let appConfig = AppConfigReducerInitialState;

  if (typeof window.drupalSettings !== 'undefined'
    && window.drupalSettings.cust_account_app !== 'undefined') {
    appConfig = window.drupalSettings.cust_account_app;
  }

  return appConfig;
};

/** Get the API Base Url from the the app config. */
export const getApiBaseUrl = () => {
  let apiBaseUrl = `//${window.location.hostname}/api`;
  const appConfig = getAppConfig();
  if (
    typeof appConfig !== 'undefined'
    && appConfig.api_base_url !== 'undefined'
  ) {
    apiBaseUrl = appConfig.api_base_url;
  }

  return apiBaseUrl;
};

/** Get the site config. */
export const getSiteConfig = () => {
  const langs = {
    depositEnabledLangs: ['en', 'en-ie'],
    giroPayEnabledLangs: ['de-de'],
    paypalEnableLangs: ['en', 'en-ie', 'de-de'],
    idealPayEnabledLangs: ['en-nl'],
    idealPayEnabledCountry: 'Netherlands',
  };
  return langs;
};
