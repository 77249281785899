import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { CookiesProvider } from 'react-cookie';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { SessionContextProvider } from './context/SessionContext';
import { PaymentContextProvider } from './context/PaymentContext';

const root = ReactDOM.createRoot(document.getElementById('cust-account'));

root.render(
  <SessionContextProvider>
    <PaymentContextProvider>
      <I18nextProvider i18n={i18next}>
        <CookiesProvider>
          <App />
        </CookiesProvider>
      </I18nextProvider>
    </PaymentContextProvider>
  </SessionContextProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
