import { useTranslation } from 'react-i18next';
import * as utils from '../../../../utils/OrderUtils';
import '../../../../translations/i18n';
import styles from './TransportDetails.module.scss';

function TransportDetails(props) {
  const { tabID, transport } = props;
  const { t } = useTranslation('common');
  const eurostarLink = 'https://managebooking.eurostar.com/uk-en';
  const transportTickets = [];
  Object.values(transport.tickets).forEach((transportTicket) => {
    transportTickets.push(
      <div className={styles.transportTicket}>
        <h3 className={styles.inboundOutboundHeading}>
          {transportTicket.transportType}
        </h3>
        <div className={styles.ticketContentJourney}>
          <h4 className={styles.destination}>
            {transportTicket.productTitle}
          </h4>
          <div className={styles.details}>
            <p className={styles.departDate}>
              {utils.formatDate(transportTicket.departDate)}
            </p>
            <div className={styles.timesChanges}>
              <p className={styles.times}>
                {transportTicket.departTime}
                -
                {transportTicket.arrivalTime}
              </p>
              <p className={styles.durationChanges}>
                <em>
                  {transportTicket.duration}
                  {', '}
                </em>
                <span>
                  {transportTicket.changes}
                  {' '}
                  {t('transport.changes')}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className={styles.ticketContentPeople}>
          <div className={styles.imageHolder}>
            <img alt="" src={transportTicket.img} />
          </div>
          <div className={styles.peopleHolder}>
            {
              transportTicket.ticketType.map((ticketType) => (
                <p className={styles.people}>
                  {ticketType.qty}
                  {' '}
                  &times;
                  {' '}
                  {ticketType.type}
                  {' '}
                  {transportTicket.typeDescription}
                </p>
              ))
            }
          </div>
        </div>
      </div>,
    );
  });

  if (transport.confirmationNumber !== '') {
    return (
      <>
        <h2 className={styles.title}>
          {t('transport.title')}
        </h2>
        <p className={styles.subtitle}>
          {t('transport.link_head1')}
          {' '}
          <a
            href={eurostarLink}
            target="_blank"
            className={styles.externalLink}
            rel="noreferrer"
            title={t('transport.external_link_title')}
          >
            {t('transport.link_title')}
          </a>
          {' '}
          {t('transport.link_head2')}
        </p>
        <div className={styles.transportTickets}>
          {transportTickets}
        </div>
        <div className={styles.bookSection}>
          {transport.confirmationNumber && (
          <p className={styles.confirmation}>
            {t('transport.confirmation_umber')}
            :
            {' '}
            <strong>
              {transport.confirmationNumber}
            </strong>
          </p>
          )}
          <p className={styles.book}>
            <a
              href={eurostarLink}
              className={styles.bookingLink}
              target="_blank"
              rel="noreferrer"
              title={t('transport.external_link_title')}
            >
              {t('transport.booking_link')}
            </a>
          </p>
        </div>
      </>
    );
  }
  return (
    <>
      <h2 className={styles.title}>
        {t('transport.title')}
      </h2>
      <div className="hotels-errata__wrapper">
        <svg className="hotels-errata__icon"><use xlinkHref="#info" /></svg>
        <p>
          {t('transport.eurostar_ticket_processing')}
        </p>
      </div>
      <div className={styles.transportTickets}>
        {transportTickets}
      </div>
    </>
  );
}

export default TransportDetails;
