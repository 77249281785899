import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { CheckoutDotComAPIPayment } from '../../../../api/CheckoutDotComAPI';
import styles from './Paypal.module.scss';

function Paypal(props) {
  const { t } = useTranslation('common');

  const { payAmount, order } = props;
  const [redirectUrl, setRedirectUrl] = useState('');

  const PaypalCaptureHandler = (e) => {
    e.preventDefault();
    CheckoutDotComAPIPayment('', 'paypal', payAmount, order.currency, order.email, order.id)
      .then((redirectUrls) => {
        setRedirectUrl(redirectUrls);
        window.location.href = redirectUrls;
      });
  };
  return (
    <div className={`${styles.paypal} gb-mask`}>
      <Helmet>
        <script src="https://www.paypal.com/sdk/js?client-id=AXeMsiPRoEPqW1QNQlCNrP-H-q2QWSHZYLjC2oH4dQiqcFGFlT2wd2sIXcXCby9tEI0MG9nE9_d9wmdA&components=messages" />
      </Helmet>
      <div className={styles.paypalFrame}>
        <iframe
          title="checkoutdotcom-paypal-iframe"
          name="paypal_frame"
          src=""
          data-amount=""
          data-response=""
          data-value=""
          height="0"
        />
      </div>
      <button className={styles.payNow} onClick={PaypalCaptureHandler} type="submit">{t('payment.pay_now')}</button>
    </div>
  );
}

export default Paypal;
