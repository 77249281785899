const domainUrl = window.location.hostname;
// eslint-disable-next-line import/no-mutable-exports
let AppConfigReducerInitialState = {};
if (domainUrl.match(/attractiontickets|global|localhost/)) {
  const globalBaseUrl = process.env.REACT_APP_API_BASE_URL ? process.env.REACT_APP_API_BASE_URL : 'https://global.test/api';
  AppConfigReducerInitialState = {
    api_base_url: globalBaseUrl,
    api_order_url: `${globalBaseUrl}/orders`,
    api_session_url: `${globalBaseUrl}/session`,
    current_language: (process.env.REACT_APP_DEFAULT_LANG) ? process.env.REACT_APP_DEFAULT_LANG : 'en',
    include_geo: (typeof process.env.REACT_APP_INCLUDE_GEO !== 'undefined') ? parseInt(process.env.REACT_APP_INCLUDE_GEO, 10) : 1,
    session_required: (typeof process.env.REACT_APP_SESSION_REQUIRED !== 'undefined') ? parseInt(process.env.REACT_APP_SESSION_REQUIRED, 10) : 0,
    brand: (process.env.REACT_APP_BRAND) ? process.env.REACT_APP_BRAND : '',
    site_name: (process.env.REACT_APP_SITE_NAME) ? process.env.REACT_APP_SITE_NAME : '',
    faq_url: (process.env.REACT_APP_FAQ_URL) ? process.env.REACT_APP_FAQ_URL : '',
    phone_number: (process.env.REACT_APP_PHONE_NUMBER) ? process.env.REACT_APP_PHONE_NUMBER : '',
  };
} else if (domainUrl.match(/americanattractions|aa/)) {
  const aaBaseUrl = process.env.REACT_APP_AA_API_BASE_URL ? process.env.REACT_APP_AA_API_BASE_URL : 'https://aa.test/api';
  AppConfigReducerInitialState = {
    api_base_url: aaBaseUrl,
    api_order_url: `${aaBaseUrl}/orders`,
    api_session_url: `${aaBaseUrl}/session`,
    session_required: (typeof process.env.REACT_APP_SESSION_REQUIRED !== 'undefined') ? parseInt(process.env.REACT_APP_SESSION_REQUIRED, 10) : 0,
    brand: (process.env.REACT_APP_AA_BRAND) ? process.env.REACT_APP_AA_BRAND : 'AA',
    site_name: (process.env.REACT_APP_AA_SITE_NAME) ? process.env.REACT_APP_AA_SITE_NAME : 'AmericanAttractions',
    faq_url: (process.env.REACT_APP_AA_FAQ_URL) ? process.env.REACT_APP_AA_FAQ_URL : 'https://aa.test/about-this-site.php',
    phone_number: (process.env.REACT_APP_AA_PHONE_NUMBER) ? process.env.REACT_APP_AA_PHONE_NUMBER : '',
  };
} else if (domainUrl.match(/uwl|universal|orlando/)) {
  const uwlBaseUrl = process.env.REACT_APP_UWL_API_BASE_URL ? process.env.REACT_APP_UWL_API_BASE_URL : 'https://uo.test/api';
  AppConfigReducerInitialState = {
    api_base_url: uwlBaseUrl,
    api_order_url: `${uwlBaseUrl}/orders`,
    api_session_url: `${uwlBaseUrl}/session`,
    session_required: typeof process.env.REACT_APP_SESSION_REQUIRED !== 'undefined' ? parseInt(process.env.REACT_APP_SESSION_REQUIRED, 10) : 0,
    brand: (process.env.REACT_APP_UWL_BRAND) ? process.env.REACT_APP_UWL_BRAND : 'UO',
    site_name: (process.env.REACT_APP_UWL_SITE_NAME) ? process.env.REACT_APP_UWL_SITE_NAME : 'UniversalWhitelabel',
    faq_url: process.env.REACT_APP_UWL_FAQ_URL ? process.env.REACT_APP_UWL_FAQ_URL : '',
    phone_number: process.env.REACT_APP_UWL_PHONE_NUMBER ? process.env.REACT_APP_UWL_PHONE_NUMBER : '',
  };
} else if (domainUrl.match(/swl|seaworld/)) {
  const swlBaseUrl = process.env.REACT_APP_SWL_API_BASE_URL ? process.env.REACT_APP_SWL_API_BASE_URL : 'https://sw.test/api';
  AppConfigReducerInitialState = {
    api_base_url: swlBaseUrl,
    api_order_url: `${swlBaseUrl}/orders`,
    api_session_url: `${swlBaseUrl}/session`,
    session_required: typeof process.env.REACT_APP_SESSION_REQUIRED !== 'undefined' ? parseInt(process.env.REACT_APP_SESSION_REQUIRED, 10) : 0,
    brand: (process.env.REACT_APP_SWL_BRAND) ? process.env.REACT_APP_SWL_BRAND : 'SW',
    site_name: (process.env.REACT_APP_SWL_SITE_NAME) ? process.env.REACT_APP_SWL_SITE_NAME : 'Sea World',
    faq_url: process.env.REACT_APP_SWL_FAQ_URL ? process.env.REACT_APP_SWL_FAQ_URL : '',
    phone_number: process.env.REACT_APP_SWL_PHONE_NUMBER ? process.env.REACT_APP_SWL_PHONE_NUMBER : '',
  };
}

export default AppConfigReducerInitialState;
