import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import styles from '../GiroPay/GiroPay.module.scss';
import { CheckoutDotComAPIPayment, CheckoutDotComAPISettings } from '../../../../api/CheckoutDotComAPI';
import * as utils from '../../../../utils/OrderUtils';

function IdealPayment(props) {
  const { t } = useTranslation('common');

  const {
    payAmount, order, failureMessage, setFailureMessage,
  } = props;
  const cardholderName = order && `${order.billing_address_fname} ${order.billing_address_lname}`;
  const [checkoutDotComPublicKey, setCheckoutDotComPublicKey] = useState('');

  const lang = utils.getLang();

  useEffect(() => {
    CheckoutDotComAPISettings()
      .then((result) => {
        setCheckoutDotComPublicKey(result.public_key);
      });
  }, [checkoutDotComPublicKey]);

  const IdealPaymentCaptureHandler = (e) => {
    e.preventDefault();
    CheckoutDotComAPIPayment('', 'ideal-hosted', payAmount, order.currency, order.email, order.id, order.billing_address_country)
      .then((redirectUrls) => {
        if (redirectUrls) {
          window.location.href = redirectUrls;
        } else {
          setFailureMessage(t('payment.failure_msg'));
        }
      });
  };

  return (
    <div>
      <p>{t('payment.payment_form.idealtab')}</p>
      <button className={styles.submit} onClick={IdealPaymentCaptureHandler} type="submit">
        {t('payment.payment_form.ideal_text')}
      </button>
    </div>
  );
}

export default IdealPayment;
