import { useState, useEffect, useCallback } from 'react';
import API from '../api/phx';
import { getAppConfig } from '../config/config';
import * as utils from '../utils/OrderUtils';

const config = getAppConfig();

const useFetchOrder = (orderId, sessionId, updateOrder, paymentStatus, forceFetch) => {
  const [isLoading, setIsLoading] = useState(true);
  const [serverError, setServerError] = useState(false);
  const [order, setOrder] = useState([]);

  if (!orderId) {
    const path = window.location.pathname.split('/');

    if (path[3] === 'order' && !Number.isNaN(Number(path[4]))) {
      orderId = sessionStorage.getItem('orderId'); /* eslint no-param-reassign: 0 */
    }
  }

  const lang = window.drupalSettings && window.drupalSettings.path.currentLanguage;
  const fetchUrl = utils.appendGeoParam(`/orders/${orderId}`, config.brand);
  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const resp = await API.get(fetchUrl, { headers: { Authorization: `Basic ${sessionId}` } });
      const data = await resp.data;
      if (data.status === 'action_required' && paymentStatus === 'payment-failed') {
        if (data.order_actions.some((action) => action.type === 'failed_payment_limit' && action.complete === 0)) {
          window.location.reload(false);
        } else {
          setIsLoading(false);
        }
      } else if (data.status === 'payment_failed' && paymentStatus === 'payment-failed') {
        window.location.reload(false);
      } else {
        setIsLoading(false);
      }
      setOrder(data);
    } catch (error) {
      setServerError(error);
      setIsLoading(false);
    }
  }, [orderId, sessionId, updateOrder, paymentStatus, forceFetch]);

  useEffect(() => {
    if (orderId) {
      fetchData().then(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      });
    }
  }, [fetchData, orderId]);

  return { isLoading, serverError, order };
};

export default useFetchOrder;
