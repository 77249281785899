import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import styles from './LoginTitle.module.scss';

function LoginTitle() {
  // Get translations for component text.
  const { t } = useTranslation('common');
  return <h1 className={styles.customerAccountsTitle}>{t('login.title')}</h1>;
}

export default LoginTitle;
