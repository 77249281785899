import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../../../translations/i18n';
import {
  Frames, CardNumber, ExpiryDate, Cvv,
} from 'frames-react';
import styles from './GiroPay.module.scss';
import { CheckoutDotComAPIPayment, CheckoutDotComAPISettings } from '../../../../api/CheckoutDotComAPI';
import * as utils from '../../../../utils/OrderUtils';

function Giropay(props) {
  const { payAmount, order } = props;
  const { t } = useTranslation('common');
  const [redirectUrl, setRedirectUrl] = useState('');

  const frames = (
    <div className={`${styles.giropayFrame} gb-mask`}>
      <iframe
        title="giropay"
        id="giropay-iframe"
        name="giropay_frame"
        src=""
        height="0"
      />
    </div>
  );

  const GiroPayCaptureHandler = (e) => {
    e.preventDefault();
    CheckoutDotComAPIPayment('', 'giropay', payAmount, order.currency, order.email, order.id)
      .then((redirectUrls) => {
        setRedirectUrl(redirectUrls);
        window.location.href = redirectUrls;
      });
  };
  return (
    <div className={styles.giropay}>
      { frames }
      <p className={styles.info}>{t('payment.giropay_text')}</p>
      <button className={styles.submit} onClick={GiroPayCaptureHandler} type="submit">{t('payment.giropay_button')}</button>
    </div>
  );
}

export default Giropay;
